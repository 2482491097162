.cart {
    text-align: center;
}

.cartTable {
    
    margin:auto;
    text-align: center;
    min-height: 50%;
    min-width: 50%;
}
.table {
    margin:auto;
    min-height: 25%;
    min-width: 60;
}

@media only screen and (max-width:1080px) {
    .cartItemImage {
        max-width: fit-content;
        height: auto;
    }
    .table_container {
        max-width:fit-content;
    }
    .cartTable {
        font-size: x-small;
    }
}

.divider {
    margin: auto;
    width: 90%;
}
.payButton {
    margin-left: 55%;
}