
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

li
{
    display:inline;
}

p {text-align: center;}

#footer {    
    bottom: 0px; display:inline-block; width: 100%;text-align: center;
}
.header {
    display: inline-block;
    height: 35%;
 }
 
 .navbar {
     float: left;
 }
 
 .shortcut {
     float:right;
 }
 .headers {
     color: rgb(246, 241, 255);
 }
.content {
    text-align: center;
}
.flex-container {
    align-items: flex-start;
    
}
.itemsbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-evenly;
    
}
.flex-item {
    display:inline-block;
    max-width: 200px;
    height: auto;
    margin-bottom: 3%;
}
p.desc {
    word-wrap: break-word;
}

@media only screen and (max-width:1080px) {
    .product-img {
        max-width: 100%;
        height: auto;
    }
    .flex-item {
        width: 25%;
    }
    .productbtn {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size:x-small;
    }
}
.aboutcontent {
    width: auto;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin: auto;
    text-align: center;
    background-color: white;
    justify-content: center;
}
.aboutus {
    background-position: center;
    position: relative;
    height: 88vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containers {
    width: auto;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    position: absolute;
    margin: auto;
    text-align: center;
    background-color: white;
    border-radius: 7px;
    justify-content: center;
}

.login {
    background-position: center;
    position: relative;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    align-items: center;
}
.containers {
    padding: 3%;
    position: absolute;
    text-align: center;
    background-color: white;
    border-radius: 7px;
    align-content: center;
}

.signup {
    background-position:center;
    position: relative;
    height: 105vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width:1080px) {
    .info-product-img {
        max-width: 100%;
        width: 20%;
        height: 20%;
    }
    .watchlist-product-img {
        max-width: 100%;
        width: 12%;
        height: 12%;
    }
    .flex-item {
        width: 25%;
    }
    .infoitemtable {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size:small;
    }
    .orderStatus {
        font-size:small;
    }
    .infoSteps {
        font-size:small;
    }
}
.container {
    width: 96%;
    min-height: 80%;
    margin: auto;
    display: inline-block;
    position: relative;
    margin-bottom: 3%;
}
.upperContainer {
    margin: auto;
    display: inline;
    margin-bottom: 3%;
    margin-top: 2%;
    display:grid;
}
.shippingSummary {
    display: inline;
    float: left;
    
    margin-bottom: 5%;
}
.billSummary {
    display: grid;
    min-height: 70%;
    border: 0.5px solid rgb(230, 230, 230);
    border-radius: 3%;
    padding: 0.5%;
}
.customerInfo {
    text-align: left;
}
.customerInfoContainer {
    margin-bottom: 5%;
}
.shippingDetails {
    text-align: left;
}
.downContainer {
    width: 100%;
}
.dvd {
    margin: auto;
    width: 90%;
    margin-left: 10%;
}
.Btn {
    display: flex;
    justify-content: space-between;
} 
.back {
    float: left;
}
.confirm {
    float: right;
}
.inputBox {
    border-radius: 5px;
}
.cartSumTable {
    margin: auto;
    width: 95%;
}
.tableContainer {
    border-top: 0.5px solid rgb(230, 230, 230);
}
.subtotal {
   border-top: 0.5px solid rgb(230, 230, 230);
}
.total {
    border-top: 0.5px solid rgb(230, 230, 230);
}
.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 96%;
    margin: auto;
}
.flex-item {
    height: 32%;
    box-sizing: border-box;
}
.product_page {
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    width: 90%;
}

.img {
    display: block;
    margin: auto;
}
.add_to_cart {
    position: relative;
    width: auto;
    margin: 15px;
    padding: 30px;
    border: solid 1px #EBEBEB;
    border-radius: 10px;
}
.cart {
    text-align: center;
}

.cartTable {
    
    margin:auto;
    text-align: center;
    min-height: 50%;
    min-width: 50%;
}
.table {
    margin:auto;
    min-height: 25%;
    min-width: 60;
}

@media only screen and (max-width:1080px) {
    .cartItemImage {
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        height: auto;
    }
    .table_container {
        max-width:-webkit-fit-content;
        max-width:-moz-fit-content;
        max-width:fit-content;
    }
    .cartTable {
        font-size: x-small;
    }
}

.divider {
    margin: auto;
    width: 90%;
}
.payButton {
    margin-left: 55%;
}
