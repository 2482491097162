.content {
    align-items: center;
}
.containers {
    padding: 3%;
    position: absolute;
    text-align: center;
    background-color: white;
    border-radius: 7px;
    align-content: center;
}

.signup {
    background-position:center;
    position: relative;
    height: 105vh;
    display: flex;
    align-items: center;
    justify-content: center;
}