.flex-container {
    align-items: flex-start;
    
}
.itemsbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-evenly;
    
}
.flex-item {
    display:inline-block;
    max-width: 200px;
    height: auto;
    margin-bottom: 3%;
}
p.desc {
    word-wrap: break-word;
}

@media only screen and (max-width:1080px) {
    .product-img {
        max-width: 100%;
        height: auto;
    }
    .flex-item {
        width: 25%;
    }
    .productbtn {
        width: fit-content;
        font-size:x-small;
    }
}