.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 96%;
    margin: auto;
}
.flex-item {
    height: 32%;
    box-sizing: border-box;
}