.containers {
    width: auto;
    min-width: fit-content;
    position: absolute;
    margin: auto;
    text-align: center;
    background-color: white;
    border-radius: 7px;
    justify-content: center;
}

.login {
    background-position: center;
    position: relative;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}