.product_page {
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    width: 90%;
}

.img {
    display: block;
    margin: auto;
}
.add_to_cart {
    position: relative;
    width: auto;
    margin: 15px;
    padding: 30px;
    border: solid 1px #EBEBEB;
    border-radius: 10px;
}