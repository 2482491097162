.header {
    display: inline-block;
    height: 35%;
 }
 
 .navbar {
     float: left;
 }
 
 .shortcut {
     float:right;
 }
 .headers {
     color: rgb(246, 241, 255);
 }