.container {
    width: 96%;
    min-height: 80%;
    margin: auto;
    display: inline-block;
    position: relative;
    margin-bottom: 3%;
}
.upperContainer {
    margin: auto;
    display: inline;
    margin-bottom: 3%;
    margin-top: 2%;
    display:grid;
}
.shippingSummary {
    display: inline;
    float: left;
    
    margin-bottom: 5%;
}
.billSummary {
    display: grid;
    min-height: 70%;
    border: 0.5px solid rgb(230, 230, 230);
    border-radius: 3%;
    padding: 0.5%;
}
.customerInfo {
    text-align: left;
}
.customerInfoContainer {
    margin-bottom: 5%;
}
.shippingDetails {
    text-align: left;
}
.downContainer {
    width: 100%;
}
.dvd {
    margin: auto;
    width: 90%;
    margin-left: 10%;
}
.Btn {
    display: flex;
    justify-content: space-between;
} 
.back {
    float: left;
}
.confirm {
    float: right;
}
.inputBox {
    border-radius: 5px;
}
.cartSumTable {
    margin: auto;
    width: 95%;
}
.tableContainer {
    border-top: 0.5px solid rgb(230, 230, 230);
}
.subtotal {
   border-top: 0.5px solid rgb(230, 230, 230);
}
.total {
    border-top: 0.5px solid rgb(230, 230, 230);
}