ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

li
{
    display:inline;
}

p {text-align: center;}

#footer {    
    bottom: 0px; display:inline-block; width: 100%;text-align: center;
}