@media only screen and (max-width:1080px) {
    .info-product-img {
        max-width: 100%;
        width: 20%;
        height: 20%;
    }
    .watchlist-product-img {
        max-width: 100%;
        width: 12%;
        height: 12%;
    }
    .flex-item {
        width: 25%;
    }
    .infoitemtable {
        width: fit-content;
        font-size:small;
    }
    .orderStatus {
        font-size:small;
    }
    .infoSteps {
        font-size:small;
    }
}